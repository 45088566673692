import { Box, Grid2, TextField, Typography, Card } from "@mui/material";
import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Stack from "@mui/material/Stack";
import Iconify from "./Iconify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import useAuth from "./contexts/hooks/useAuth";
import { toast } from "react-toastify";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { apiPostMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoute";
const initialValues = {
  new_password: "",
  confirm_password: "",
};
export const ResetPassword = () => {
  const navigate = useNavigate();
  const location =  useLocation()
  const queryParams = new URLSearchParams(location.search);
  const uuid = queryParams.get("uidb64")
  const token = queryParams.get("token");
  const validationSchema = Yup.object().shape({
    new_password: Yup.string().required("Password is required!"),
    confirm_password: Yup.string()
      .required("Confirm Password is required!")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });
  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const res = await apiPostMethod(
        `${allApiUrl?.RESET_PASSWORD_LINK}${uuid}/${token}/`,
        {
          new_password: values.new_password,
          confirm_password: values.confirm_password,
        }
      );
      toast.success(res?.message);
      navigate("/login");
    } catch (err) {
      console.log(err);
    }
  };
  const renderForm = (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              className="inputText"
              autoComplete="off"
              name="new_password"
              label="New Password"
              value={values.new_password}
              onChange={(e) => {
                setFieldValue("new_password", e.target.value);
              }}
              error={touched.new_password && Boolean(errors.new_password)}
              helperText={touched.new_password ? errors.new_password : ""}
            />
            <TextField
              className="inputText"
              autoComplete="off"
              name="confirm_password"
              label="Confirm Password"
              value={values.email}
              onChange={(e) => {
                setFieldValue("confirm_password", e.target.value);
              }}
              error={
                touched.confirm_password && Boolean(errors.confirm_password)
              }
              helperText={
                touched.confirm_password ? errors.confirm_password : ""
              }
            />
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="inherit"
            className=" blueButton"
            style={{
              fontSize: { xs: "14px", sm: "14px", md: "16px" },
              marginTop: "8px",
            }}
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            Update Password
          </LoadingButton>
        </form>
      )}
    </Formik>
  );
  const data = [
    {
      heading: "InsideSEP",
      subheading: "Your gateway to essebtial patents by patentskart",
    },
    {
      heading: "Helping you build a smarter\nIP strategy",
      subheading: "Make Your Business a Success With Us",
    },
  ];
  return (
    <Box height="100vh">
      <Grid2 container minHeight="100vh" className="loginSection">
        <Grid2
          item
          size={6}
          className="imageSection"
          sx={{
            background: "url('/assets/images/login.jpg') top left no-repeat ",
            backgroundSize: "cover",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",

            "&:before": {
              position: "absolute",
              content: '""',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgb(34 51 115 / 38%)",
            },
          }}
        >
          <Carousel
            autoPlay
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            showIndicators={false} // This hides the dots
            interval={2000}
            transitionTime={500}
          >
            {data.map((item, index) => (
              <div key={index}>
                <Box
                  sx={{
                    position: "relative",
                    zIndex: 999, // Ensure the content is above everything else
                    px: 5,
                    maxWidth: "60%",
                    mx: "auto",
                    // pl: "150px",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <Typography
                    fontSize={{ xs: "22px", sm: "22px", md: "24px" }}
                    fontWeight={500}
                    fontFamily="'Jost', sans-serif"
                    lineHeight={{ xs: "35px", sm: "35px", md: "40px" }}
                    component="div"
                  >
                    {item.heading.split("\n").map((line, idx) => (
                      <React.Fragment key={idx}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </Typography>
                  <Typography
                    fontSize={{ xs: "14px", sm: "14px", md: "16px" }}
                    fontWeight={500}
                    fontFamily="'Jost', sans-serif"
                    lineHeight="30px"
                    mt={2}
                    textTransform="capitalize"
                  >
                    {item.subheading}
                  </Typography>
                </Box>
              </div>
            ))}
          </Carousel>
        </Grid2>
        <Grid2
          item
          size={6}
          className="imageSection"
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box display="flex" justifyContent="flex-end" py={4} px={6}>
            <img
              src="/assets/icons/logo.png"
              alt="Logo"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "200px",
                objectFit: "contain",
                imageRendering: "crisp-edges",
              }}
            />
          </Box>
          <Card
            sx={{
              p: 5,
              width: 1,
              maxWidth: 500,
              zIndex: 2,
              // mt: 15,
              ml: 12,
            }}
          >
            <Typography
              variant="h5"
              mb={3}
              sx={{
                fontWeight: 600,
                textAlign: "center",
                fontSize: { xs: "18px", sm: "18px", md: "18px" },
              }}
            >
              Reset Password
            </Typography>
            {renderForm}
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  );
};
