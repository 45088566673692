import {
  Box,
  Grid2,
  TextField,
  Typography,
  Card,
} from "@mui/material";
import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Stack from "@mui/material/Stack";
import Iconify from "./Iconify";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import useAuth from "./contexts/hooks/useAuth";
import { toast } from "react-toastify";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const initialValues = {
  email: "",
  password: "",
};
export const Login = () => {
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be 6 character length")
      .required("Password is required!"),
    // email: Yup.string()
    //   .email("Invalid Email address")
    //   .required("Email is required!"),
    email: Yup.string().required("Username is required"),
  });
  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await login(values.email, values.password);
    } catch (e) {
      toast.error(e.data.message);
    } finally {
      setSubmitting(false);
    }
  };
  const renderForm = (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              className="inputText"
              autoComplete="off"
              name="email"
              label="Email address"
              value={values.email}
              onChange={(e) => {
                setFieldValue("email", e.target.value);
              }}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email ? errors.email : ""}
            />
            <TextField
              autoComplete="off"
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              onBlur={handleBlur}
              value={values.password}
              onChange={handleChange}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password ? errors.password : ""}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ my: "13px" }}
          >
            <div
              variant="subtitle2"
              onClick={() => navigate("/forgot-password")}
              className="loginfx"
              style={{cursor:"pointer"}}
            >
              Forgot password?
            </div>
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="inherit"
            className=" blueButton"
            style={{
              fontSize: { xs: "14px", sm: "14px", md: "16px" },
              marginTop: "8px",
            }}
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            Login
          </LoadingButton>
        </form>
      )}
    </Formik>
  );
  const data = [
    {
      heading: "InsideSEP",
      subheading: "Your gateway to essebtial patents by patentskart",
    },
    {
      heading: "Helping you build a smarter\nIP strategy",
      subheading: "Make Your Business a Success With Us",
    },
  ];
  return (
    <Box height="100vh">
      <Grid2 container minHeight="100vh" className="loginSection">
        <Grid2
          item
          size={6}
          className="imageSection"
          sx={{
            background: "url('/assets/images/login.jpg') top left no-repeat ",
            backgroundSize: "cover",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",

            "&:before": {
              position: "absolute",
              content: '""',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgb(34 51 115 / 38%)",
            },
          }}
        >
          <Carousel
            autoPlay
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            showIndicators={false}  // This hides the dots
            interval={2000}
            transitionTime={500}
          >
            {data.map((item, index) => (
              <div key={index}>
                <Box
                  sx={{
                    position: "relative",
                    zIndex: 999,  // Ensure the content is above everything else
                    px: 5,
                    maxWidth: "60%",
                    mx: "auto",
                    // pl: "150px",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <Typography
                    fontSize={{ xs: "22px", sm: "22px", md: "24px" }}
                    fontWeight={500}
                    fontFamily="'Jost', sans-serif"
                    lineHeight={{ xs: "35px", sm: "35px", md: "40px" }}
                    component="div"
                  >
                    {item.heading.split("\n").map((line, idx) => (
                      <React.Fragment key={idx}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </Typography>
                  <Typography
                    fontSize={{ xs: "14px", sm: "14px", md: "16px" }}
                    fontWeight={500}
                    fontFamily="'Jost', sans-serif"
                    lineHeight="30px"
                    mt={2}
                    textTransform="capitalize"
                  >
                    {item.subheading}
                  </Typography>
                </Box>
              </div>
            ))}
          </Carousel>
        </Grid2>
        <Grid2
          item
          size={6}
          className="imageSection"
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box display="flex" justifyContent="flex-end" py={4} px={6}>
            <img
              src="/assets/icons/logo.png"
              alt="Logo"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "200px",
                objectFit: "contain",
                imageRendering: "crisp-edges",
              }}
            />
          </Box>
          <Card
            sx={{
              p: 5,
              width: 1,
              maxWidth: 500,
              zIndex: 2,
              // mt: 15,
              ml: 12,
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 600, fontSize: { xs: "18px", sm: "18px", md: "18px" } }}>Welcome!</Typography>
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                // textAlign: "center",
                // color: "#aeb5bd",
                fontSize: { xs: "14px", sm: "14px", md: "14px" },
              }}
            >
              Sign Into Your Account
            </Typography>
            <Typography variant="body2" sx={{ mt: 0, mb: 5 }}></Typography>
            {renderForm}
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  );
};
