// export const createQueryString = (queryObject) => {
//   Object.keys(queryObject).forEach((key) => {
//     if (queryObject[key] === undefined || queryObject[key] === null || queryObject[key] === "") {
//       delete queryObject[key];
//     }
//   });

//   const queryString = Object.entries(queryObject)
//     .map(([key, value]) => {
//       return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
//     })
//     .join("&");

//   return queryString ? `?${queryString}` : "";
// };

export const createQueryString = (queryObject) => {
  Object.keys(queryObject).forEach((key) => {
    if (queryObject[key] === undefined || queryObject[key] === null || queryObject[key] === "") {
      delete queryObject[key];
    }
  });

  // Create query string with array values formatted as TECH[]=index0
  const queryString = Object.entries(queryObject)
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(item => `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`);
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return queryString ? `?${queryString}` : "";
};

