import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  CircularProgress,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDashboardContext } from "./contexts/ForDashboard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { apiGetMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoute";
import TableHeadRow, {
  StyledTableCell,
  StyledTableRow,
} from "../shared/tableui/TableHead";
import { headCells, headCells2, headCells3 } from "../shared/constant";

const Loader = () => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      zIndex: 9999,
    }}
  >
    <CircularProgress />
  </Box>
);

export const ViewData = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchData, data, loading } = useDashboardContext();
  const queryParams = new URLSearchParams(location.search);
  const dpig = queryParams.get("dpig");
  const [filteredData, setFilteredData] = useState(null);
  const [ipData, setIpData] = useState([]);
  const [selectedFormCategory, setSelectedFormCategory] = useState(id);
  const currentResult = location.state;

  useEffect(() => {
    const fetchFirstData = async () => {
      try {
        const response = await apiGetMethod(
          `${allApiUrl.FIRST_SEARCH_DASHOARD}${currentResult}`
        );
        setIpData(response?.result);
      } catch (err) {
        console.log(err);
      }
    };

    fetchFirstData();
  }, [currentResult, id]);

  useEffect(() => {
    fetchData(id);
  }, [id]);
  useEffect(() => {
    if (dpig && data) {
      const [displayNumber, index] = dpig.split(".").map(Number); // Split and convert to numbers

      const selectedData = data.find(
        (item) => item.DIPG_DISPLAY_NUMBER === displayNumber
      );
      if (selectedData && selectedData.array) {
        const item = selectedData.array[index - 1]; // Adjusting for zero-based index
        setFilteredData(item);
      }
    }
  }, [dpig, data]);

  const handleCategoryChange = (e, DIPG) => {
    setSelectedFormCategory(e.target.value);
    navigate(`/viewdata/${e.target.value}?dpig=${DIPG}.1`, {
      state: currentResult,
    });
  };
  useEffect(() => {
    if (data && data.length > 0) {
      handleCategoryChange(
        { target: { value: id } },
        data[0]?.DIPG_DISPLAY_NUMBER
      );
    }
  }, [data, id]);

  let getQuery = localStorage.getItem("setQuery");
  const keyNames = getQuery
    ?.substring(1)
    ?.split("&")
    ?.map((pair) => pair?.split("=")?.[0]?.replace("[]", ""));
  const displayText = keyNames?.join(" / ");
  return (
    <>
      {loading && <Loader />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          mt: 2,
          ml: 5,
        }}
        onClick={() => navigate("/")}
      >
        <ArrowBackIcon sx={{ mr: 1 }} />
        <Typography
          variant="h6"
          fontSize={{ xs: "12px", sm: "12px", md: "16px" }}
          fontWeight={600}
        >
          {id} ({displayText})
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          mt: 2,
          ml: 5,
          mb: 3,
        }}
      >
        <FormControl>
          <FormLabel fontWeight={600}>IPRD NUMBER</FormLabel>
          <Select
            fullWidth
            size="small"
            value={selectedFormCategory}
            onChange={(e) =>
              handleCategoryChange(e, data?.[0]?.DIPG_DISPLAY_NUMBER)
            }
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  overflowY: "auto",
                },
              },
            }}
          >
            {ipData?.length > 0 ? (
              ipData.map((category, index) => (
                <MenuItem
                  key={`choice1__${index}`}
                  value={category.IPRD_REFERENCE}
                >
                  {category.IPRD_REFERENCE}
                </MenuItem>
              ))
            ) : (
              <MenuItem>No category Available</MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
      <Card sx={{ maxWidth: "xl", margin: "auto" }}>
        <CardContent>
          {filteredData && (
            <>
              <Box sx={{ flexGrow: 1, p: 3 }}>
                <Typography fontWeight={600} fontSize="16px">
                  Standards/Specification Overview
                </Typography>
                <TableContainer sx={{ maxWidth: "xl", mt: 3 }}>
                  <Table>
                    <TableHeadRow headCells={headCells} />
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell>
                          {filteredData.STANDARD || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Technology || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Sub_Technology || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.RECOMMENDATION || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.ILLUSTRATIVE_PART || "-"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ flexGrow: 1, p: 3 }}>
                <Typography fontWeight={600} fontSize="16px">
                  Patent Information
                </Typography>
                <TableContainer sx={{ maxWidth: "xl", mt: 3 }}>
                  <Table>
                    <TableHeadRow headCells={headCells2} />
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell>
                          {filteredData.Application_Number || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Publication_Number ? (
                            <a
                              href={`https://patents.google.com/patent/${filteredData.Publication_Number}/en?oq=${filteredData.Publication_Number}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {filteredData.Publication_Number}
                            </a>
                          ) : (
                            "-"
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Patent_Number ? (
                            <a
                              href={`https://patents.google.com/patent/${filteredData.Patent_Number}/en?oq=${filteredData.Patent_Number}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {filteredData.Patent_Number}
                            </a>
                          ) : (
                            "-"
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Title || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Inventor || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.PATENT_OWNER || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Current_Assignee || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.COUNTRY_CODE || "-"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ flexGrow: 1, p: 3 }}>
                <Typography fontWeight={600} fontSize="16px">
                  Project/Patent Evaluations
                </Typography>
                <TableContainer sx={{ maxWidth: "xl", mt: 3 }}>
                  <Table>
                    <TableHeadRow headCells={headCells3} />
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell>
                          {filteredData.IPRD_SIGNATURE_DATE || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Ess_To_Project ? "YES" : "No"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Ess_To_Standard ? "YES" : "No"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.App_pub_pat_Number || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.DIPG_EXTERNAL_ID || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.PATT_ID || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.DIPG_PATF_ID || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.DIPG_ID || "-"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};
