import React from "react";
import { useLocation, Navigate } from "react-router-dom";
const GuestAuth = ({ children }) => {
    const loginUserInfo = localStorage.getItem("token")
    const location = useLocation();
    return loginUserInfo ? (
        <Navigate to="/" state={{ from: location }} replace />
    ) : (
        <>{children}</>
    );
};

export default GuestAuth;