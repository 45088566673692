import { TableHead, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
export default function TableHeadRow({ headCells }) {
    return (
        <TableHead className="tableHead">
            <TableRow sx={{
        "& > th" : {
          border : "none",
          color : "white"
        }
      }}>
                {headCells?.map((hCell) => (
                    <TableCell key={hCell.id} className="nowrap" style={{
                        fontFamily:'Inter, sans-serif',
                        color: '#FFFFFF', 
                        fontWeight: 'bold', 
                        padding: '12px 16px', 
                        textAlign: 'left', 
                        fontSize:"14px",
                        fontWeight:600
                    }}>
                        {hCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
export const StyledTableNoData = (
    { colSpan }
) => {
    return (
        <StyledTableRow
            style={{
                height: 53,
            }}
        >
            <TableCell
                align="center"
                colSpan={colSpan}
            >
                No Data Found
            </TableCell>
        </StyledTableRow>
    )
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));