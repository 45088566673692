import {
    Box,
    TextField,
    Card,
    styled,
    Grid2,
    Autocomplete,
} from "@mui/material";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useNavigate } from "react-router-dom";

const CardHeader = styled(Box)(() => ({
    display: "flex",
    paddingLeft: "24px",
    paddingRight: "24px",
    marginBottom: "12px",
    alignItems: "center",
    justifyContent: "space-between",
}));

const Title = styled("span")(() => ({
    fontSize: "1rem",
    fontWeight: "500",
    textTransform: "capitalize",
}));


function ProfilePage() {
    const navigate = useNavigate()
    let userName =  localStorage.getItem("username")
let email = localStorage.getItem("email")
    return (
        <>
            <Card elevation={3} sx={{ pt: "20px", mb: 3 }} className="cardFix">
                <CardHeader className="cardHeadBorder">
                    <Title className="cardHead">Edit Profile</Title>
                </CardHeader>

                <Box p={4} height="50%">
                    <Grid2 container columnSpacing={2} rowSpacing={2}>
                        <Grid2 size={6}>
                            <TextField
                                fullWidth
                                type="email"
                                name="email"
                                label="Email"
                                variant="outlined"
                                placeholder="John@example.com"
                                className="inputHeight"
                                value = {email}
                                disabled
                            />

                        </Grid2>
                        <Grid2 size={6}>
                            <TextField
                                fullWidth
                                type="number"
                                name="username"
                                label="UserName"
                                variant="outlined"
                                className="inputHeight"
                                value = {userName}
                                disabled
                            />
                        </Grid2>
                        <Grid2 item lg={12} sx={{ marginTop: "20xpx" }}>
                            <Button className="blueButton cancel" onClick={()=>navigate("/")}>
                                Cancel
                            </Button>
                        </Grid2>
                    </Grid2>
                </Box>

            </Card>
        </>
    );
}
export default ProfilePage;