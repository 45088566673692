export const validRoutes = [
    {
      path: "/",
      name: "dashboard",
      title: "Dashboard",
    },
    {
      path: "/profile",
      name: "profile",
      title: "Profile",
    },
    {
      path: "/viewdata/:id",
      name: "viewdata",
      title: "viewdata",
    },
  ];