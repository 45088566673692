import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { validRoutes } from "../../../shared/varialble/path";
import { matchPath } from "react-router-dom";
const RequireAuth = ({ children }) => {
  const loginUserInfo = localStorage.getItem("token");
  const location = useLocation();
  const checkPathName = validRoutes?.some((route) =>
    matchPath(route.path, location.pathname)
  );
  return !checkPathName ? (
    <Navigate to="/unauthorized" />
  ) : !loginUserInfo ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : (
    <>{children}</>
  );
};
export default RequireAuth;