import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid2,
  TextField,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  Link,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TableHeadRow, {
  StyledTableCell,
  StyledTableRow,
} from "../shared/tableui/TableHead";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { createQueryString } from "../shared/function/AllFunction";
import { apiGetMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoute";
import { useDashboardContext } from "./contexts/ForDashboard";
import moment from "moment/moment";
import { toast } from "react-toastify";
import TableRowsLoader from "./TableRowsLoader";

export const Dashboard = () => {
  const {
    setSelectedSSO,
    setSelectedTech,
    selectedSSO,
    selectedTech,
    setLoading,
    sidePanelOpen,
    setSidePanelOpen,
  } = useDashboardContext();
  const navigate = useNavigate();
  const headCells = [
    { id: "Reference", label: "Reference No." },
    { id: "Type", label: "Type" },
    { id: "Patent_Owner", label: "Patent Owner" },
    { id: "sso", label: "SSO's" },
    { id: "tech_domain", label: "Tech Domain" },
    { id: "Declaration", label: "Declaration Date" },
    { id: "actions", label: "Action" },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataFiltered, setDataFiltered] = useState({ result: [] });
  const [tableListing, setTableListing] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [newQueryObject, setNewQueryObject] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [IPRD_REFERENCE, setIPRD_REFERENCE] = useState([])
  const [PATENT_OWNER, setPATENT_OWNER] = useState([])
  const [Publication_Number, setPublication_Number] = useState([])
  const [Sub_Technology, setSub_Technology] = useState([])
  const [data, setData] = useState({
    STANDARD_SET: [],
    PATENT_OWNER: [],
    IPRD_REFERENCE: [],
    PATENT_NUM: "",
    STANDARD: [],
    Sub_Tech: [],
    DATE_FROM: null,
    DATE_TO: null,
    TECH: [],
  });
  const handlePatentChange = async (e) => {
    const { name, value } = e.target;
    // Update the PATENT_NUM in data as the user types
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Fetch suggestions only if there is some input
    if (value.length > 0) {
      let queryObject = {
        q: value,
      };
      const qryStr = createQueryString(queryObject);
      try {
        const response = await apiGetMethod(
          `${allApiUrl.PATENT_NUMBER}${qryStr}`
        );
        setSuggestions(response.results);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setData((prevData) => ({
      ...prevData,
      PATENT_NUM: suggestion.text,
    }));
    setSuggestions([]);
  };
  const handleDateChange = (name) => (newValue) => {
    if (
      name === "DATE_TO" &&
      data.DATE_FROM &&
      newValue.isBefore(data.DATE_FROM)
    ) {
      toast.error("End date cannot be lesser than start date");
      return;
    }

    setData((prevData) => ({
      ...prevData,
      [name]: newValue ? dayjs(newValue) : null,
    }));
  };

  const handleSearch = async () => {
    setTableListing(true);
    setIsLoading(true);
    setSidePanelOpen(false);
    try {
      let queryObject = {
        STANDARD_SET: selectedSSO?.length > 0 ? selectedSSO : null,
        PATENT_OWNER: data?.PATENT_OWNER || null,
        IPRD_REFERENCE: data?.IPRD_REFERENCE || null,
        PATENT_NUM: data?.PATENT_NUM || null,
        STANDARD: data?.STANDARD || null,
        Sub_Tech: data?.Sub_Tech || null,
        DATE_FROM:
          (data?.DATE_FROM &&
            moment(new Date(data?.DATE_FROM)).format("YYYY-MM-DD")) ||
          null,
        DATE_TO:
          (data?.DATE_TO &&
            moment(new Date(data?.DATE_TO)).format("YYYY-MM-DD")) ||
          null,
        TECH: selectedTech?.length > 0 ? selectedTech : null || [],
      };
      const qryStr = createQueryString(queryObject);
      setNewQueryObject(qryStr);
      localStorage.setItem("setQuery", qryStr);
      const response = await apiGetMethod(
        `${allApiUrl.FIRST_SEARCH_DASHOARD}${qryStr}`
      );
      setDataFiltered(response);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setDataFiltered({ result: [] });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tableListing) {
      handleSearch();
    }
  }, [tableListing, selectedSSO, selectedTech]);
  useEffect(() => {
    const fetchFirstData = async () => {
      try {
        const response = await apiGetMethod(
          `${allApiUrl.INPUT_SEARCH}`
        );
        setIPRD_REFERENCE(response?.result?.IPRD_REFERENCE)
        setPATENT_OWNER(response?.result?.PATENT_OWNER)
        setPublication_Number(response?.result?.Publication_Number)
        setSub_Technology(response?.result?.Sub_Technology)
        // setIpData(response?.result);
      } catch (err) {
        console.log(err);
      }
    };

    fetchFirstData();
  }, []);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to first page
  };
  //   const handleDPIGClick = (id, itemId) => {
  //     navigate(`/viewdata/${id}?dpig=${itemId}`);
  // };
  const handleReset = () => {
    setData({
      STANDARD_SET: [],
      PATENT_OWNER: [],
      IPRD_REFERENCE: [],
      PATENT_NUM: "",
      STANDARD: [],
      Sub_Tech: [],
      DATE_FROM: null,
      DATE_TO: null,
      TECH: [],
    });
    setCurrentPage(0);
    setTableListing(false);
    setSelectedSSO([]);
    setSelectedTech([]);
  };
  // Calculate the current data to be displayed
  const indexOfLastResult = (currentPage + 1) * rowsPerPage;
  const indexOfFirstResult = indexOfLastResult - rowsPerPage;
  const currentResults =
    dataFiltered?.result?.slice(indexOfFirstResult, indexOfLastResult) || [];
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {isloading && <Box component="div" className="loaderPosition">
        <CircularProgress sx={{ color: "#0B3E68" }} />
      </Box>}
      <Card sx={{ maxWidth: "xl", margin: "auto", backgroundColor: "#EEF7FF" }}>
        <CardContent>
          {tableListing === true && (
            <Box sx={{ flexGrow: 1, p: 3 }}>
              <Grid2 container spacing={3}>
                <Grid2 size={3}>
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        title="STANDARD SETTING ORGANIZATION"
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                        }}
                      >
                        SSO
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                        >
                          {dataFiltered?.count?.cat_count?.SSO || 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                        >
                          /{dataFiltered?.count?.total || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid2>
                <Grid2 size={3}>
                  {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}> */}
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        title="STANDARD"
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                        }}
                      >
                        STANDARD
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                        >
                          {dataFiltered?.count?.cat_count?.STANDARD || 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                        >
                          /{dataFiltered?.count?.total || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                  {/* </Box> */}
                </Grid2>
                <Grid2 size={3}>
                  {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}> */}
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        title="TECHNOLOGY"
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                        }}
                      >
                        TECHNOLOGY
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                        >
                          {dataFiltered?.count?.cat_count?.Technology || 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                        >
                          /{dataFiltered?.count?.total || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                  {/* </Box> */}
                </Grid2>
                <Grid2 size={3}>
                  {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}> */}
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                        }}
                        title="SUB-TECHNOLOGY"
                      >
                        SUB-TECHNOLOGY
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                        >
                          {dataFiltered?.count?.cat_count?.Sub_Technology || 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                        >
                          /{dataFiltered?.count?.total || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                  {/* </Box> */}
                </Grid2>
                <Grid2 size={3}>
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                        }}
                        title="PUBLICATION NUMBER"
                      >
                        PUBLICATION NUMBER
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                        >
                          {dataFiltered?.count?.cat_count?.Publication_Number ||
                            0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                        >
                          /{dataFiltered?.count?.total || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid2>
                <Grid2 size={3}>
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                        }}
                        title="PATENT OWNER"
                      >
                        PATENT OWNER
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                        >
                          {dataFiltered?.count?.cat_count?.PATENT_OWNER || 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                        >
                          /{dataFiltered?.count?.total || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid2>
                <Grid2 size={3}>
                  {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}> */}
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                        }}
                        title="INVENTOR"
                      >
                        INVENTOR
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                        >
                          {dataFiltered?.count?.cat_count?.Inventor || 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                        >
                          /{dataFiltered?.count?.total || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                  {/* </Box> */}
                </Grid2>
              </Grid2>
            </Box>
          )}
          <Divider orientation="horizontal" sx={{ mb: 3, mt: 3 }} />
          <Grid2 container spacing={3}>
            <Grid2 size={4}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  color="#333333"
                  fontWeight={600}>IPRD Reference Number / Doc no.</Typography>
                <Autocomplete
                  multiple
                  options={IPRD_REFERENCE}
                  value={data.IPRD_REFERENCE || []}
                  onChange={(event, newValue) => {
                    setData((prevData) => ({
                      ...prevData,
                      IPRD_REFERENCE: newValue,
                    }));
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="outlined"
                      sx={{ fontSize: "14px" }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        sx={{ marginRight: 1 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  sx={{ fontSize: "14px", maxHeight: 300 }}
                />
              </Box>
            </Grid2>
            <Grid2 size={4}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  color="#333333"
                  fontWeight={600}>
                  Standard/ Specification/ Recommendation
                </Typography>
                <Autocomplete
                  multiple
                  options={Publication_Number}
                  value={data.STANDARD || []}
                  onChange={(event, newValue) => {
                    setData((prevData) => ({
                      ...prevData,
                      STANDARD: newValue,
                    }));
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="outlined"
                      sx={{ fontSize: "14px" }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        sx={{ marginRight: 1 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  sx={{ fontSize: "14px", maxHeight: 300 }}
                />
              </Box>
            </Grid2>


            <Grid2 size={4}>
              <Box sx={{ mb: 1, position: "relative" }}>
                <Typography
                  color="#333333"
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  fontWeight={600}
                >
                  Patent No./ Application No./ Publication No.
                </Typography>
                <TextField
                  label="Outlined"
                  variant="outlined"
                  fullWidth
                  name="PATENT_NUM"
                  value={data.PATENT_NUM}
                  onChange={handlePatentChange}
                  autoComplete="off"
                />
                {suggestions.length > 0 && (
                  <List
                    sx={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      right: 0,
                      bgcolor: "background.paper",
                      zIndex: 1,
                    }}
                  >
                    {suggestions.map((suggestion) => (
                      <ListItem key={suggestion.id} disablePadding>
                        <ListItemButton
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion.text}
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            </Grid2>
            <Grid2 size={6}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  color="#333333"
                  fontWeight={600}>Assignee/ Inventor/ Declaring Comp./ Patent Owner</Typography>
                <Autocomplete
                  multiple
                  options={PATENT_OWNER}
                  value={data.PATENT_OWNER || []}
                  onChange={(event, newValue) => {
                    setData((prevData) => ({
                      ...prevData,
                      PATENT_OWNER: newValue,
                    }));
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="outlined"
                      sx={{ fontSize: "14px" }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        sx={{ marginRight: 1 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  sx={{ fontSize: "14px", maxHeight: 300 }}
                />
              </Box>
            </Grid2>
            <Grid2 size={6}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  color="#333333"
                  fontWeight={600}>Committee/ Sub-Tech</Typography>
                <Autocomplete
                  multiple
                  options={Sub_Technology}
                  value={data.Sub_Tech || []}
                  onChange={(event, newValue) => {
                    setData((prevData) => ({
                      ...prevData,
                      Sub_Tech: newValue,
                    }));
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="outlined"
                      sx={{ fontSize: "14px" }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        sx={{ marginRight: 1 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  sx={{ fontSize: "14px", maxHeight: 300 }}
                />
              </Box>
            </Grid2>
            <Grid2 size={6}>
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  color="#333333"
                  fontWeight={600}
                >
                  Declaration Date ( From )
                </Typography>
                <DatePicker
                  sx={{ width: "100%" }}
                  value={data.DATE_FROM}
                  onChange={handleDateChange("DATE_FROM")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid2>
            <Grid2 size={6}>
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  fontWeight={600}
                  color="#333333"
                >
                  Declaration Date ( To )
                </Typography>
                <DatePicker
                  sx={{ width: "100%" }}
                  value={data.DATE_TO}
                  onChange={handleDateChange("DATE_TO")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid2>
          </Grid2>
        </CardContent>
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F7C300",
              color: "#000",
              padding: "12px 24px",
              fontSize: "16px",
              textAlign: "center",
              fontWeight: 600,
              fontSize: { xs: "14px", sm: "14px", md: "14px" },
              "&:hover": {
                backgroundColor: "#FFE376",
              },
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
          <Button
            variant="outlined"
            sx={{
              marginLeft: 2,
              padding: "12px 24px",
              fontWeight: 600,
              fontSize: { xs: "14px", sm: "14px", md: "14px" },
              textAlign: "center",
            }}
            onClick={handleReset}
          >
            Reset
          </Button>
        </Box>
      </Card>
      {tableListing === true && (
        <>
          <TableContainer
            component={Card}
            sx={{ maxWidth: "xl", margin: "auto", mt: 3, borderRadius: "12px" }}
          >
            <Table>
              <TableHeadRow headCells={headCells} />
              <TableBody>
                {isloading ? (
                  <TableRowsLoader rowsNum={10} columnNum={headCells.length} />
                ) : currentResults?.length > 0 ? (
                  currentResults?.map((item, index) => {
                    return (
                      <StyledTableRow key={`idx_${index}`}>
                        <StyledTableCell>
                          {item?.IPRD_REFERENCE}
                        </StyledTableCell>
                        <StyledTableCell>{item?.Type}</StyledTableCell>
                        <StyledTableCell>{item?.PATENT_OWNER}</StyledTableCell>
                        <StyledTableCell>
                          {item?.STANDARD_SETTING}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item?.Technology || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item?.IPRD_SIGNATURE_DATE}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            onClick={() =>
                              navigate(
                                `/viewdata/${item?.IPRD_REFERENCE}?dpig=${item.DIPG_DISPLAY_NUMBER}.1`,
                                { state: newQueryObject }
                              )
                            } // Handle the click to navigate
                            style={{
                              textDecoration: "none",
                              color: "#008DC4",
                              background: "none",
                              border: "none",
                              padding: 0,
                              cursor: "pointer",
                              fontSize: "inherit", // Inherit font size to match the table style
                            }}
                          >
                            View
                          </Button>
                          {/* <Link
                            href={`/viewdata/${item?.IPRD_REFERENCE}?dpig=${item.DIPG_DISPLAY_NUMBER}`}
                          style={{textDecoration:"none" ,color:"#008DC4"}}
                          >
                            View
                          </Link> */}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={7}
                      textAlign="center"
                      fontSize="14px"
                      fontWeight={500}
                    >
                      No Data Found
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={dataFiltered?.result?.length || 0} // Total count from filtered results
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}
    </LocalizationProvider>
  );
};
